import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Validator from "validator";
import { AuthAction } from "../Authentication/AuthSlice";

function EmployeeForm(props) {
  const Dispatch = useDispatch();

  const ID = props.id;

  const OldImage = axios.defaults.baseURL + props.emp_avatar;

  const [ChangeCon, setChangeCon] = useState(true);

  useEffect(() => {
    if (ID) {
      setChangeCon((prev) => !prev);
    }
  }, [ID]);

  return (
    <>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>
      <form onSubmit={props.SubmitHandler}>
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <h1 className="ml-10 text-lg font-medium leading-6 text-gray-900 mt-10">
              Employee Information
            </h1>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="emp_name"
                          value={props.emp_name}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          type="text"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="emp_email"
                          value={props.emp_email}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: `.@`,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Valid Mail",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Mobile No.
                        </label>
                        <input
                          type="tel"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="emp_mobile"
                          value={props.emp_mobile}
                          onChange={(e) => {
                            if (
                              Validator.isNumeric(e.target.value) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please use Numerical Only",
                                })
                              );
                            }
                          }}
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Employee ID
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          name="emp_id"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          value={props.emp_id}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Designation of Employee
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="emp_designation"
                          value={props.emp_designation}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                          required
                        />
                      </div>

                      <div className="col-span-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Photo
                        </label>
                        <div className="mt-1 flex items-center">
                          <img
                            className="w-10 h-10 rounded-full"
                            src={
                              props.ImgPath
                                ? props.ImgPath
                                : props.emp_avatar.length > 0
                                ? OldImage
                                : "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                            }
                            alt="Rounded avatar"
                          />

                          <label
                            htmlFor="change"
                            className="ml-5 bg-white py-2 px-3 mr-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            style={{ cursor: "pointer" }}
                          >
                            Change
                          </label>
                          <p style={{ fontSize: "12px" }}>PNG, JPG up to 1MB</p>

                          <input
                            type="file"
                            id="change"
                            name="emp_avatar"
                            className="sr-only"
                            onChange={(e) => {
                              if (
                                (e.target.files[0].type.split("/")[1] ===
                                  "jpeg" ||
                                  e.target.files[0].type.split("/")[1] ===
                                    "jpg" ||
                                  e.target.files[0].type.split("/")[1] ===
                                    "png") &&
                                e.target.files[0].size < 1024000
                              ) {
                                props.ChangeHandler(e, e.target.files[0]);
                              } else {
                                Dispatch(
                                  AuthAction.showNotification({
                                    type: "error",
                                    message:
                                      "Please Upload on JPEG, JPG & PNG up to 1MB",
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-10 mb-10" />
        {/* <!-- control unit --> */}
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <h1 className="ml-10 text-lg font-medium leading-6 text-gray-900 mt-10">
              Control Unit
            </h1>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2 ">
                        <label className="block text-sm font-medium text-gray-700">
                          Monitor
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="monitor"
                          value={props.monitor}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2 ">
                        <label className="block text-sm font-medium text-gray-700">
                          Mouse
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="mouse"
                          value={props.mouse}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2 ">
                        <label className="block text-sm font-medium text-gray-700">
                          Keyboard
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="keyboard"
                          value={props.keyboard}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2 ">
                        <label className="block text-sm font-medium text-gray-700">
                          System Unit
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="system_unit"
                          value={props.system_unit}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          RAM
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="RAM"
                          value={props.RAM}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Processor
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="processor"
                          value={props.processor}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Motherboard
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="motherboard"
                          value={props.motherboard}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Storage
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="storage"
                          value={props.storage}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Graphic card
                        </label>
                        <input
                          type="text"
                          id="small-input"
                          className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-xs focus:ring-blue-500 focus:border-blue-500"
                          name="graphic_card"
                          value={props.graphic_card}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="message"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                        >
                          Gadgets
                        </label>
                        <textarea
                          id="message"
                          rows="4"
                          className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                          name="gadgets"
                          value={props.gadgets}
                          onChange={(e) => {
                            if (
                              Validator.isAlphanumeric(
                                e.target.value,
                                "en-US",
                                {
                                  ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                                  allow_enter: true,
                                }
                              ) ||
                              e.target.value.length === 0
                            ) {
                              props.ChangeHandler(e);
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                            }
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3  flex justify-end space-x-4 sm:px-6">
                    <button
                      type="button"
                      className={
                        ChangeCon
                          ? "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm cursor-not-allowed font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                          : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                      }
                      disabled={ChangeCon}
                      onClick={props.ReAssign}
                    >
                      Re-assign System
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200"></div>
        </div>
      </div>
    </>
  );
}

export default EmployeeForm;
