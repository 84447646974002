import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { AuthAction } from "../Authentication/AuthSlice";

import EmployeeForm from "./EmployeeForm";

function Employee() {
  const Dispatch = useDispatch();

  const History = useHistory();

  const [emp_data, setemp_data] = useState({
    emp_name: "",
    emp_email: "",
    emp_mobile: "",
    emp_id: "",
    emp_designation: "",
    emp_avatar: [],
    monitor: "",
    keyboard: "",
    mouse: "",
    system_unit: "",
    processor: "",
    storage: "",
    motherboard: "",
    RAM: "",
    graphic_card: "",
    gadgets: "",
  });

  const [ImgPath, setImgPath] = useState("");

  const ChangeHandler = (event, file) => {
    const Value = event.target.value;

    if (event.target.name === "emp_avatar") {
      setemp_data({
        ...emp_data,
        emp_avatar: file,
      });

      setImgPath(URL.createObjectURL(file));
    } else {
      setemp_data({
        ...emp_data,
        [event.target.name]: Value,
      });
    }
  };

  const EmployeeData = new FormData();

  const SubmitHandler = (event) => {
    event.preventDefault();
    Dispatch(AuthAction.Loader());

    for (let keys in emp_data) {
      if (!!emp_data[keys]) {
        EmployeeData.append(keys, emp_data[keys]);
      }
    }

    axios
      .post("/emp/add", EmployeeData)
      .then((res) => {
        if (res.status === 201) {
          Dispatch(AuthAction.Loader());
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Employee Data Successfully Saved",
            })
          );

          setemp_data({
            emp_name: "",
            emp_email: "",
            emp_mobile: "",
            emp_id: "",
            emp_designation: "",
            emp_avatar: [],
            monitor: "",
            keyboard: "",
            mouse: "",
            system_unit: "",
            processor: "",
            storage: "",
            motherboard: "",
            RAM: "",
            graphic_card: "",
            gadgets: "",
          });
        }
      })
      .then(() => History.push("/employee_list"))
      .catch((err) => {
        Dispatch(AuthAction.Loader());
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );

        Object.keys(emp_data).map((key) => {
          return EmployeeData.delete(key);
        });
      });
  };

  return (
    <EmployeeForm
      ImgPath={ImgPath}
      emp_name={emp_data.emp_name}
      emp_email={emp_data.emp_email}
      emp_mobile={emp_data.emp_mobile}
      emp_id={emp_data.emp_id}
      emp_designation={emp_data.emp_designation}
      emp_avatar={emp_data.emp_avatar}
      monitor={emp_data.monitor}
      keyboard={emp_data.keyboard}
      mouse={emp_data.mouse}
      system_unit={emp_data.system_unit}
      processor={emp_data.processor}
      storage={emp_data.storage}
      motherboard={emp_data.motherboard}
      graphic_card={emp_data.graphic_card}
      gadgets={emp_data.gadgets}
      RAM={emp_data.RAM}
      ChangeHandler={ChangeHandler}
      SubmitHandler={SubmitHandler}
    />
  );
}

export default Employee;
