import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { AuthAction } from "../Authentication/AuthSlice";
import axios from "axios";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 250) : text}
            <span onClick={toggleReadMore} style={{color:"blue"}} className="read-or-hide">
                {isReadMore ? "...read more" : " "}
            </span>
        </p>
    );
};
const TitleReadMore = ({ children }) => {
    const text = children;
    const [isReadMoreTitle, setIsReadMoreTitle] = useState(true);
    const toggleReadMore = () => {
    };
    return (
        <p className="text">
            {isReadMoreTitle ? text.slice(0, 20) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMoreTitle ? "...read more" : ""}
            </span>
        </p>
    );
};



const BlogList = () => {
    const Dispatch = useDispatch();
const changeStatus=(obj)=>{
    console.log(obj)
    axios
    .post("/category/api/v1/updateBlogStatus",obj)
    .then((res) => {
        console.log(res)
        if(obj.status==true){
            Dispatch(
                AuthAction.showNotification({
                  type: "success",
                  message: 'Accepted Successfully!!',
                })
              );
        }else{
            Dispatch(
                AuthAction.showNotification({
                  type: "error",
                  message: 'Rejected Successfully!',
                })
              );
        }
        List();
    })
    .catch((err) => {
      Dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "something is missing",
        })
      );
    })
}
    const accepted = (val) =>{ 
        console.log(val);
        changeStatus({id:val,status:true})
       }
    const rejected = (val) =>{ 
        console.log(val);
        changeStatus({id:val,status:false})
    }
    const [delId,setDelID]=useState(null)
    const canceled = () =>{ 
        setDelID(null)
        toast('Cancel Successfully!');
   }
    const deleted = () => {
        axios
        .post("/category/api/v1/deleteBlog",{id:delId})
        .then((res) => {
            List();
            Dispatch(
                AuthAction.showNotification({
                  type: "success",
                  message: "Delete Successfully!",
                })
              );
              setDelID(null);
          
        })
        .catch((err) => {
          Dispatch(
            AuthAction.showNotification({
              type: "error",
              message: "something is missing",
            })
          );
        })
      
   }
   
    const handleShow = (val) => {
        setDelID(val)
        setShow(true)
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [list, setList] = useState([]);

    const List=()=>{
        setList([]);
        axios
        .get("category/api/v1/Listblog")
        .then((res) => {
          console.log(res)
           if (res.data.status === true) {
            setList(res.data.response)
           }else{
              Dispatch(
                  AuthAction.showNotification({
                    type: "error",
                    message: res.data.message,
                  })
                );  
           }
        })
        .catch((err) => {
          Dispatch(
            AuthAction.showNotification({
              type: "error",
              message: "Please Enter Valid Data",
            })
          );
        })

    }
   
    useEffect(()=>{
        List();
    },[])


    return (
        <>
            <Row className="m-0">
                <Row>
                  
                  {
                    list.length>0 ? list.map((item,index)=>{

                        return(
                        <>
                  <Card style={{padding:'2%', margin:'1%'}}>
                  <Row className="flex-column-reverse flex-sm-row">
                  
                  <Col lg={9} md={12} sm={12} className="order-sm-2">
                  
                                        <div className="mb-2">
                                            <div className="heading">
                                            <Link to={`/blog_detail/${item._id}`}  className="text-dark mb-2"><h2 className="fs-6" style={{ color: '#38BDF8' }}> {item.blog_title} </h2></Link>
                                            </div>

                                        </div>

                                        <div className="text-dark mb-2"  >
                                        <Link to={`/blog_detail/${item._id}`} className="text-dark mb-2">
                                            <ReadMore>
                                              {item.blog_plaintxt}
                                            </ReadMore>
                                            </Link>
                                        </div>
                  
                    <Row>
                    <Col>
                    <div>
                  
                                           
                                                {
                                                item.status==true?
                                                <button type="button" className="btn" style={{ backgroundColor: '#d71a1ad4' }} onClick={()=>{rejected(item._id)}}>Reject</button>
                                                : <button type="button" className="btn" style={{ backgroundColor: '#1e6c1ed1' }} onClick={()=>{accepted(item._id)}} >Accept</button>
                                              
                                                }
                                               
                                            
                                        
                   
                <Link to={`/edit_blog/${item._id}`}>
                <SaveAsOutlinedIcon className="text-primary pointer cursor-pointer mx-2" />
                </Link>

                <DeleteOutlineOutlinedIcon className="text-primary pointer cursor-pointer" onClick={()=>{handleShow(item._id)}} />
                </div>
                 </Col>
                  </Row>
                             
                  </Col>
                  <Col lg={3} md={12} sm={12} className="order-sm-1" >
                  <div className="image-handler">
                                    <img className="w-100 rounded-top" src={`https://admin.shunyaekai.tech/uploads/${item.blogmedium_url}`} position='top' alt='...'
                                        style={{ height: '250px', objectFit: 'fill' }}
                                    />
                                </div>
                  </Col>
                
                  </Row>
                  </Card>
                        {/* <Link to="/blog_view"> */}
                            {/* <Card className="mt-2 mb-2">  
                                <div className="image-handler">
                                    <img className="w-100 rounded-top" src={`http://localhost:4003/uploads/${item.blogmedium_url}`} position='top' alt='...'
                                        style={{ height: '250px', objectFit: 'fill' }}
                                    />
                                </div>
                                <div>
                                    <Card className="p-3">
                                        <div className="mb-2">
                                            <div className="heading" style={{ height: '50px'}}>
                                            <Link to={`/blog_detail/${item._id}`}  className="text-dark mb-2"><h2 className="fs-6" style={{ color: '#38BDF8' }}> <TitleReadMore>{item.blog_title} </TitleReadMore></h2></Link>
                                            </div>

                                        </div>

                                        <div className="text-dark mb-2"  >
                                        <Link to={`/blog_detail/${item._id}`} className="text-dark mb-2">
                                            <ReadMore>
                                              {item.blog_plaintxt}
                                            </ReadMore>
                                            </Link>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <div className="blog-btn">
                                                {
                                                item.status==true?
                                                <button type="button" className="btn" style={{ backgroundColor: '#d71a1ad4' }} onClick={()=>{rejected(item._id)}}>Reject</button>
                                                : <button type="button" className="btn" style={{ backgroundColor: '#1e6c1ed1' }} onClick={()=>{accepted(item._id)}} >Accept</button>
                                              
                                                }
                                               
                                            </div>
                                            <div>

                                                <Link to={`/edit_blog/${item._id}`}>
                                                <SaveAsOutlinedIcon className="text-primary pointer cursor-pointer mx-2" />
                                                </Link>

                                                <DeleteOutlineOutlinedIcon className="text-primary pointer cursor-pointer" onClick={()=>{handleShow(item._id)}} />
                                            </div>
                                            </div>
                                    </Card>
                                </div>
                            </Card> */}
                        {/* </Link> */}
                      
                  

                        </>
                        )

                    }):<h1>No record found</h1>
                  }

                   

                </Row>
            </Row>

            <div>
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton> */}
          <Modal.Title className="px-3 pt-2">
            <DeleteIcon className="text-primary mx-2" />
            <span style={{ fontFamily: 'monospace' }}>
              Delete
            </span>

          </Modal.Title>
          <div>
            <hr />
            <p className="px-5 pt-3 pb-3 fs-6">
              Are You Sure to delete this item !
            </p>
            <hr />
          </div>
          <div className="blog-btn d-flex align-items-center justify-content-end m-2" >
            <Button className="mx-2 btn" variant="secondary"
              onClick={() => {
                handleClose();
                canceled();
              }}>
              Cancel
            </Button>
            <Button className="btn" variant="primary"
              onClick={() => {
                handleClose();
                deleted();
              }}>
              Delete
            </Button>
          </div>
        </Modal>
      </div>
        </>
    )
}

export default BlogList