import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Nav from "../Nav";
import Sidebar from "../Sidebar";

// component import
import BlogDetails from "../Blog/BlogDetails";

function PrivateRoute({ Component, Role, name, ...rest }) {
  const Auth = useSelector((state) => state.Auth.isAuth);

  const isLoggedIn = localStorage.getItem("session");

  const RestrictTo = Role;
  return (
    <>
      <Sidebar>
        <div className="w-4/5 overflow-y-scroll xl:w-full">
          <Nav />
          {/* <div className="min-h-full max-h-full"> */}
          <div>
            <Route
              {...rest}
              render={(props) => {
                if ((Auth || isLoggedIn) && RestrictTo.includes(isLoggedIn)) {
                  return <Component {...props} />;
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: "/",
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  );
                }
              }}
            />
          </div>
        </div>
      </Sidebar>
    </>
  );
}

export default PrivateRoute;
