import React from "react";

import QrCode from "react-qr-code";

function QRCard(props) {
  return (
    <div className="max-w-sm bg-white p-4 rounded-lg border border-gray-200 shadow-md drop-shadow-lg">
      <div ref={props.store} className="flex flex-col items-center text-center">
        <QrCode
          value={props.id}
          bgColor="#FFFFFF"
          fgColor="#000000"
          size={64}
        />
        <p className="mb-3 font-normal text-gray-700">{props.title}</p>
      </div>
      <div className="p-4 space-x-2">
        <button
          type="button"
          className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center"
          onClick={props.Close}
        >
          Close
        </button>
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center"
          onClick={props.onClick}
        >
          Print
        </button>
      </div>
    </div>
  );
}

export default QRCard;
