import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";
import CareerForm from "./Career-Form";

function UpdateCareer() {
  const Dispatch = useDispatch();

  const History = useHistory();

  const { id } = useParams();

  const [Career, setCareer] = useState({
    profile_name: "",
    profile_description: "",
    qualification: "",
    experience: "",
    industry_type: "",
    employment_type: "",
    key_skill: "",
    location: "",
    working_days: "",
    responsibility: "",
    no_of_job: "",
    status: "",
  });

  useEffect(() => {
    axios.get(`/career/get/${id}`).then((res) => {
      if (res.status === 200) {
        const newData = res.data.data;

        setCareer({
          ...Career,
          profile_name: newData.profile_name,
          profile_description: newData.profile_description,
          qualification: newData.qualification,
          experience: newData.experience,
          industry_type: newData.industry_type,
          employment_type: newData.employment_type,
          key_skill: newData.key_skill,
          location: newData.location,
          working_days: newData.working_days,
          responsibility: newData.responsibility,
          no_of_job: newData.no_of_job,
          status: newData.status,
        });
      }
    });
  }, [id]);

  const ChangeHandler = (e) => {
    const Value = e.target.value;

    setCareer({
      ...Career,
      [e.target.name]: Value,
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    Dispatch(AuthAction.Loader());

    axios
      .post(`/career/edit/${id}`, Career)
      .then((res) => {
        if (res.status === 200) {
          Dispatch(AuthAction.Loader());
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Career Successfully Update",
            })
          );
        }
      })
      .then(() =>
        setTimeout(() => {
          History.push("/career_list");
        }, 1500)
      )
      .catch((err) => {
        Dispatch(AuthAction.Loader());
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );
      });
  };

  return (
    <CareerForm
      profile_name={Career.profile_name}
      profile_description={Career.profile_description}
      qualification={Career.qualification}
      experience={Career.experience}
      industry_type={Career.industry_type}
      employment_type={Career.employment_type}
      key_skill={Career.key_skill}
      location={Career.location}
      working_days={Career.working_days}
      responsibility={Career.responsibility}
      no_of_job={Career.no_of_job}
      status={Career.status}
      onChange={ChangeHandler}
      onSubmit={SubmitHandler}
      onCancel={() => History.push("/career_list")}
    />
  );
}

export default UpdateCareer;
