import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Validator from "validator";
import { Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { AuthAction } from "./Authentication/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { PostBlog, getBlogs, BlogAction } from "../Blog-Reducer/Reducer";
import axios from "axios";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 60) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " "}
      </span>
    </p>
  );
};


function Blog() {
  const accepted = () => toast('Accept Successfully!');
  const rejected = () => toast('Reject Successfully!');
  const canceled = () => toast('Cancel Successfully!');
  const deleted = () => toast('Delete Successfully!');

  // Delet modal 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const validationSchema = Yup.object().shape({
    titleName: Yup.string().required('Title name is required'),
    authorName: Yup.string().required('Author name is required'),
    selectDate: Yup.string().required('date is required'),
  });

  const initialValues = { titleName: '', authorName: '', selectDate: '' };
  const onSubmit = (values) => console.log('submit form', values);


  const formik = useFormik({ initialValues, validationSchema, onSubmit });


  const Dispatch = useDispatch();
  const { PostData, SinglePost, UpdateModal } = useSelector(
    (state) => state.Blog
  );

  const Role = localStorage.getItem("session");

  const Blog_FormData = new FormData();
  const [BlogModal, setBlogModal] = useState(false);
  const [ImageName, setImageName] = useState("");
  const [BlogData, setBlogData] = useState({
    title: "",
    about: "",
    category: "",
    image: "",
    page:
      Role === "ShunyaEkai"
        ? "Shunya Ekai"
        : Role === "RIOT"
          ? "RIOT Learning"
          : "",
  });

  const [UpdateBlog, setUpdateBlog] = useState({
    title: "",
    about: "",
    category: "",
    image: "",
    page:
      Role === "ShunyaEkai"
        ? "Shunya Ekai"
        : Role === "RIOT"
          ? "RIOT Learning"
          : "",
  });

  const ChangeHandler = (event, Name, File) => {
    const Value = event.target.value;

    if (event.target.name === "image") {
      setImageName(Name);
      setBlogData({
        ...BlogData,
        image: File,
      });
    } else {
      setBlogData({
        ...BlogData,

        [event.target.name]: Value,
      });
    }
  };

  const ChangeHandler_one = (event, Name, File) => {
    const Value = event.target.value;

    if (event.target.name === "image") {
      setImageName(Name);
      setUpdateBlog({
        ...UpdateBlog,
        image: File,
      });
    } else {
      setUpdateBlog({
        ...UpdateBlog,

        [event.target.name]: Value,
      });
    }
  };

  const SubmitHandler = (event) => {
    event.preventDefault();
    if (BlogData.page === "Shunya Ekai") {
      if (!BlogData.category) {
        return Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Select Category",
          })
        );
      }
    }

    Blog_FormData.append("blog_title", BlogData.title);
    Blog_FormData.append("blog_description", BlogData.about);
    Blog_FormData.append("blog_categories", BlogData.category);
    Blog_FormData.append("blog_image", BlogData.image);
    Blog_FormData.append("page", BlogData.page);

    Dispatch(PostBlog("add", Blog_FormData));

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const SubmitHandler_one = (event) => {
    event.preventDefault();

    if (!UpdateBlog.category) {
      return Dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Please Select Category",
        })
      );
    }

    Blog_FormData.append("blog_title", UpdateBlog.title);
    Blog_FormData.append("blog_description", UpdateBlog.about);
    Blog_FormData.append("blog_categories", UpdateBlog.category);
    Blog_FormData.append("blog_image", UpdateBlog.image);

    Dispatch(PostBlog(`edit/${SinglePost._id}`, Blog_FormData));

    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  useEffect(() => {
    Dispatch(getBlogs(BlogData.page));
  }, [Dispatch]);

  return (
    <>
      <div>
        {/* <div className="flex justify-end px-4 sm:px-6" >
          <button
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
            onClick={() => setBlogModal(true)}
            disabled={BlogModal}
          >
            Create New Blog
          </button>
        </div> */}
        {BlogModal && (
          <div className="blog-modal">
            <div className="mt-5 drop-shadow-lg md:mt-0 md:col-span-2 lg:w-3/12">
              <form onSubmit={SubmitHandler}>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <div className="mt-1">
                        <input
                          id="title"
                          name="title"
                          className="p-1 pl-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Blog Title"
                          value={BlogData.title}
                          onChange={(e) => {
                            Validator.isAlpha(e.target.value, "en-US", {
                              ignore: " ",
                            }) || e.target.value.length === 0
                              ? ChangeHandler(e)
                              : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="shadow-sm pl-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Blog Description"
                          value={BlogData.about}
                          onChange={(e) => {
                            Validator.isAlphanumeric(e.target.value, "en-US", {
                              ignore: " ,;:.@$%&()_-=/+*'",
                            }) || e.target.value.length === 0
                              ? ChangeHandler(e)
                              : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) -'",
                                })
                              );
                          }}
                        />
                      </div>
                    </div>

                    {Role === "Admin" ? (
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Page
                          </label>

                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            name="page"
                            value={BlogData.page}
                            onChange={(e) => {
                              e.target.selectedIndex !== 0
                                ? ChangeHandler(e)
                                : Dispatch(
                                  AuthAction.showNotification({
                                    type: "error",
                                    message: "Please Select Page",
                                  })
                                );
                            }}
                          >
                            <option value="">Select Page</option>
                            <option value="Shunya Ekai">Shunya Ekai</option>
                            <option value="RIOT Learning">RIOT Learning</option>
                          </select>
                        </div>
                      </div>
                    ) : null}

                    {BlogData.page === "Shunya Ekai" ? (
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Categories
                          </label>

                          <select
                            id="countries"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            name="category"
                            value={BlogData.category}
                            onChange={(e) => {
                              e.target.selectedIndex !== 0
                                ? ChangeHandler(e)
                                : Dispatch(
                                  AuthAction.showNotification({
                                    type: "error",
                                    message: "Please Select Category",
                                  })
                                );
                            }}
                          >
                            <option value="">Select Category</option>
                            <option value="AI">Artificial Intelligence</option>
                            <option value="ROBOTICS">Robotics</option>
                            <option value="IOT">IOT</option>
                            <option value="ML">Mechine Learning</option>
                            <option value="3D-DESIGN">3D Printing</option>
                          </select>
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Cover photo
                      </label>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          {!ImageName ? (
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <p className="font-light dark:text-white">
                              {ImageName}
                            </p>
                          )}

                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="image"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  if (
                                    (e.target.files[0].type.split("/")[1] ===
                                      "jpeg" ||
                                      e.target.files[0].type.split("/")[1] ===
                                      "jpg" ||
                                      e.target.files[0].type.split("/")[1] ===
                                      "png") &&
                                    e.target.files[0].size < 1024000
                                  ) {
                                    ChangeHandler(
                                      e,
                                      e.target.files[0].name,
                                      e.target.files[0]
                                    );
                                  } else {
                                    Dispatch(
                                      AuthAction.showNotification({
                                        type: "error",
                                        message:
                                          "Please Upload on JPEG, JPG & PNG up to 1MB",
                                      })
                                    );
                                  }
                                }}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 1MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 flex justify-end space-x-2 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none"
                      onClick={() => {
                        setBlogModal(false);
                        setBlogData({
                          title: "",
                          about: "",
                          category: "",
                          image: "",
                        });
                        setImageName("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {UpdateModal && (
          <div className="blog-modal">
            <div className="mt-5 drop-shadow-lg md:mt-0 md:col-span-2 lg:w-3/12">
              <form onSubmit={SubmitHandler_one}>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                      <label
                        htmlFor="title"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <div className="mt-1">
                        <input
                          id="title"
                          name="title"
                          className="p-1 pl-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Blog Title"
                          value={UpdateBlog.title || SinglePost.blog_title}
                          onChange={(e) => {
                            Validator.isAlpha(e.target.value, "en-US", {
                              ignore: " ",
                            })
                              ? ChangeHandler_one(e)
                              : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="shadow-sm pl-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder="Blog Description"
                          value={
                            UpdateBlog.about || SinglePost.blog_description
                          }
                          onChange={(e) => {
                            Validator.isAlphanumeric(e.target.value, "en-US", {
                              ignore: " ,;:.@$%&()_-=/+*",
                            })
                              ? ChangeHandler_one(e)
                              : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) -'",
                                })
                              );
                          }}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label className="block text-sm font-medium text-gray-700">
                          Page
                        </label>

                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          name="page"
                          value={UpdateBlog.page || SinglePost.page}
                          disabled
                        >
                          <option value="">Select Page</option>
                          <option value="Shunya Ekai">Shunya Ekai</option>
                          <option value="RIOT Learning">RIOT Learning</option>
                        </select>
                      </div>
                    </div>

                    {SinglePost.page === "Shunya Ekai" ? (
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-3 sm:col-span-2">
                          <label
                            htmlFor="company-website"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Categories
                          </label>

                          <select
                            id="countries"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            name="category"
                            value={
                              UpdateBlog.category || SinglePost.blog_categories
                            }
                            onChange={(e) => {
                              e.target.selectedIndex !== 0
                                ? ChangeHandler_one(e)
                                : Dispatch(
                                  AuthAction.showNotification({
                                    type: "error",
                                    message: "Please Select Category",
                                  })
                                );
                            }}
                          >
                            <option value="">Select Category</option>
                            <option value="AI">Artificial Intelligence</option>
                            <option value="ROBOTICS">Robotics</option>
                            <option value="IOT">IOT</option>
                            <option value="ML">Mechine Learning</option>
                            <option value="3D-DESIGN">3D Printing</option>
                          </select>
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Cover photo
                      </label>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          {!`${SinglePost.blog_image}`.split("/")[1] ? (
                            <svg
                              className="mx-auto h-12 w-12 text-gray-400"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 48 48"
                              aria-hidden="true"
                            >
                              <path
                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          ) : (
                            <p className="font-light dark:text-white">
                              {`${SinglePost.blog_image}`.split("/")[1]}
                            </p>
                          )}
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="image"
                                type="file"
                                className="sr-only"
                                onChange={(e) => {
                                  if (
                                    (e.target.files[0].type.split("/")[1] ===
                                      "jpeg" ||
                                      e.target.files[0].type.split("/")[1] ===
                                      "jpg" ||
                                      e.target.files[0].type.split("/")[1] ===
                                      "png") &&
                                    e.target.files[0].size < 1024000
                                  ) {
                                    ChangeHandler_one(
                                      e,
                                      e.target.files[0].name,
                                      e.target.files[0]
                                    );
                                  } else {
                                    Dispatch(
                                      AuthAction.showNotification({
                                        type: "error",
                                        message:
                                          "Please Upload on JPEG, JPG & PNG up to 1MB",
                                      })
                                    );
                                  }
                                }}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG, GIF up to 1MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 flex justify-end space-x-2 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none"
                      onClick={() => {
                        Dispatch(BlogAction.toggleModal(false));
                        setUpdateBlog({
                          title: "",
                          about: "",
                          category: "",
                          image: "",
                          page: "",
                        });
                        setImageName("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Card Section */}
        <div className="mt-5 flex gap-5 flex-wrap justify-center">
          {PostData.map((data) => (
            <Card
              key={data._id}
              id={data._id}
              image_url={`${axios.defaults.baseURL}${data.blog_image}`}
              title={data.blog_title}
              about={data.blog_description}
              page={data.page}
              category={data.blog_categories}
            />
          ))}
        </div>
        {/* Card Section End Here */}
      </div>



      {/* Blog Code start from here */}
      <Row className="row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 m-0">
        <Col>
          <Card>
            <div className="image-handler">
              <img className="w-100 rounded-top" src='https://images.unsplash.com/photo-1679458118229-6ac5b35757d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60' position='top' alt='...'
                style={{ height: '350px', objectFit: 'fill' }}
              />
            </div>
            <div>
              <Card className="p-3">
                <div className="mb-2">
                  <Link to="#" className="heading">
                    <h2 className="fs-6" style={{ color: '#38BDF8' }}>Claritas est etiam processus dynamicus.</h2>
                  </Link>
                  <div className="d-flex align-items-center justify-content-between mt-2" tag="span" clampss="2" style={{ color: '#38BDF8' }}>
                    <span className='fw-bolder fs-6'>26/02/2023</span>
                    <span className='fw-bolder fs-6'>Plazathemes</span>
                  </div>
                </div>

                <Link to="/blog_detail" className="text-dark mb-2">
                  <ReadMore>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                    and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </ReadMore>
                </Link>


                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="blog-btn">
                    <button type="button" className="btn" style={{ backgroundColor: '#1e6c1ed1' }} onClick={accepted} >Accept</button>
                    <button type="button" className="btn" style={{ backgroundColor: '#d71a1ad4' }} onClick={rejected}>Reject</button>

                  </div>
                  <div>

                    <Link to="/edit_blog">
                      <SaveAsOutlinedIcon className="text-primary pointer cursor-pointer mx-2" />
                    </Link>
                    <DeleteOutlineOutlinedIcon className="text-primary pointer cursor-pointer" onClick={handleShow} />
                  </div>
                </div>
              </Card>
            </div>
          </Card>
        </Col>
      </Row>

      <div>
        <Modal show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton> */}
          <Modal.Title className="px-3 pt-2">
            <DeleteIcon className="text-primary mx-2" />
            <span style={{ fontFamily: 'monospace' }}>
              Delete
            </span>

          </Modal.Title>
          <div>
            <hr />
            <p className="px-5 pt-3 pb-3 fs-6">
              Are You Sure to delete this item !
            </p>
            <hr />
          </div>
          <div className="blog-btn d-flex align-items-center justify-content-end m-2" >
            <Button className="mx-2 btn" variant="secondary"
              onClick={() => {
                handleClose();
                canceled();
              }}>
              Cancel
            </Button>
            <Button className="btn" variant="primary"
              onClick={() => {
                handleClose();
                deleted();
              }}>
              Delete
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}
export default Blog;