import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";

function CareerList() {
  const [List, setList] = useState([]);

  const Dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/career/gets")
      .then((res) => {
        if (res.status === 200) {
          setList(res.data.data);
        }
      })
      .catch((err) => {
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Internal Server Error",
          })
        );
      });
  }, [Dispatch]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Profile Name
            </th>
            <th scope="col" className="px-6 py-3">
              Qualification
            </th>
            <th scope="col" className="px-6 py-3">
              Experience
            </th>
            <th scope="col" className="px-6 py-3">
              Location
            </th>
            <th scope="col" className="px-6 py-3">
              Working Day
            </th>
            <th scope="col" className="px-6 py-3">
              No of Job Opening
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Post At
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {List.map((data) => {
            return (
              <tr key={data._id} className="bg-white border-b ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  {data.profile_name}
                </th>
                <td className="px-6 py-4 overflow-elip">
                  {data.qualification}
                </td>
                <td className="px-6 py-4">{data.experience}</td>
                <td className="px-6 py-4">{data.location}</td>
                <td className="px-6 py-4">{data.working_days} day</td>
                <td className="px-6 py-4">{data.no_of_job}</td>
                <td className="px-6 py-4">
                  {data.status === "1" ? "Active" : "Deactive"}
                </td>
                <td className="px-6 py-4">
                  {new Date(data.createdAt).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 ">
                  <Link
                    to={`/career/update/${data._id}`}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CareerList;
