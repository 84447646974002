import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { toast } from "react-toastify";

const initialState = {
  isAuth: false,
  isLoading: false,
};

const AuthSlicer = createSlice({
  name: "Auth_Slicer",
  initialState,
  reducers: {
    Authenticate: function (state, action) {
      state.isAuth = action.payload;
    },

    showNotification: function (state, action) {
      toast[action.payload.type](action.payload.message);
    },

    Loader: function (state) {
      state.isLoading = !state.isLoading;
    },
  },
});

export const AuthAction = AuthSlicer.actions;

export const LoginHandler = (userData) => {
  return async (dispatch) => {
    const Login = async () => {
      // for Loading
      dispatch(AuthAction.Loader());

      const Res = await axios.post("/login", userData);

      if (Res.status === 200) {
        localStorage.setItem("session", Res.data.Data.role);

        // for Loading
        dispatch(AuthAction.Loader());

        dispatch(AuthAction.Authenticate(true));
        return dispatch(
          AuthAction.showNotification({
            type: "success",
            message: "Successfully Logged In",
          })
        );
      }
    };

    try {
      await Login();
    } catch (err) {
      // for Loading
      dispatch(AuthAction.Loader());

      dispatch(AuthAction.Authenticate(false));
      dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Invalid Email and Password",
        })
      );
    }
  };
};

export const LogoutHandler = () => {
  return async (dispatch) => {
    const Logout = async () => {
      // for Loading
      dispatch(AuthAction.Loader());

      const Res = await axios.get("/logout");

      if (Res.status === 200) {
        // for Loading
        dispatch(AuthAction.Loader());

        dispatch(AuthAction.Authenticate(false));

        localStorage.removeItem("session");

        return dispatch(
          AuthAction.showNotification({
            type: "success",
            message: "Successfully Logged Out",
          })
        );
      }
    };

    try {
      await Logout();
    } catch (err) {
      // for Loading
      dispatch(AuthAction.Loader());

      dispatch(AuthAction.Authenticate(true));
      dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Something Went Wrong...",
        })
      );
    }
  };
};

export default AuthSlicer;
