import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Routes from "../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { LogoutHandler } from "./Authentication/AuthSlice";
import { BsChevronRight } from "react-icons/bs"

function Sidebar(props) {
  const Dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.Auth);

  const History = useHistory();

  const Role = localStorage.getItem("session");

  const LogoutFunc = (event) => {
    event.preventDefault();

    Dispatch(LogoutHandler());

    setTimeout(() => {
      History.push("/");
    }, 1000);
  };

  const [submenu, setSubmenu] = React.useState(true);
  const [toggel, setToggle] = React.useState(true);

  function toggleSubmenu(name) {
    console.log(name)
    Routes.forEach((value) => {
      value.name === name && value.submenu && value.toggle === false
        ? (value.toggle = true)
        : (value.toggle = false);
    });
  }
  return (
    <>
      {isLoading && (
        <div className="new-loader">
          <div className="loader1 mt-4"></div>
        </div>
      )}
      <div className="flex justify-between h-screen">
        <aside className="w-64 h-screen" aria-label="Sidebar">
          <div className="overflow-y-auto py-4 px-3 bg-sky-100 rounded h-full 2xl:h-screen flex flex-col justify-between">
            <div>
              <p className="flex items-center mb-5 flex-col lg:flex-row">
                <img
                  src="https://shunyaekai.tech/image/logo2.png"
                  className="h-6 mr-3 sm:h-11"
                  alt="Flowbite Logo"
                />
                <span className="self-center text-xl font-semibold whitespace-nowrap">
                  Shunya Ekai
                  <br />
                  Admin Panel
                </span>
              </p>
              <ul className="space-y-2">
                {Routes.map((route, index) => {
                  if (
                    route.name === "Update Course" ||
                    route.name === "Update Career" ||
                    route.name === "Update Employee Data" ||
                    !route.role.includes(Role)
                  ) {
                    return null;
                  }
                  return (
                    <>
                      {
                        route.name !== 'Blog Details' &&
                        route.name !== 'Edit Blog' &&
                        route.name !== 'Blog View' &&
                        route.name !== 'Add Blog' &&
                        route.name !== 'Blog List' &&
                        route.name !== 'Blogs' &&
                        <div key={index + 1}>
                          <li className={`${window.location.pathname === route.path && 'bg-active'}`} >
                            <NavLink onClick={() => toggleSubmenu(route.name)}
                              to={route.path}
                              className="flex items-center p-2 justify-between text-base rounded-lg hover:bg-sky-400 hover:text-white hover:font-medium"
                            >
                              <div>
                                <FontAwesomeIcon icon={route.icon} />
                                <span className="ml-3">{route.name}</span>
                              </div>
                              {route.submenu && <BsChevronRight
                                className={`${route.toggle && "rotate-90"}`}
                              />}

                            </NavLink>
                          </li>
                          {route.submenu && route.toggle && (
                            <ul>
                              {route.submenuItems.map((submenuItem, index) =>
                                <NavLink to={submenuItem.path} key={++index} >
                                  <li className={`${window.location.pathname === submenuItem.path && 'bg-active'} flex items-center p-2 px-3 mt-1 text-base rounded-lg hover:bg-sky-400 hover:text-white hover:font-medium`}
                                  >
                                    <FontAwesomeIcon icon={submenuItem.icon} />
                                    <span className="ml-3">{submenuItem.name}</span>
                                  </li>
                                </NavLink>
                              )}
                            </ul>
                          )}
                        </div>
                      }
                    </>
                  );
                })}
                <hr />
              </ul>
            </div>
            <div>
              <ul className="space-y-2">
                <li onClick={LogoutFunc}>
                  <p
                    style={{ cursor: "pointer" }}
                    className="flex items-center p-2 bg-red-600 text-white font-normal rounded-lg hover:bg-red-700 "
                  >
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Log Out
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </aside>

        {props.children}
      </div >
    </>
  );
}

export default Sidebar;
