import { useDispatch } from "react-redux";
import { AuthAction } from "../Authentication/AuthSlice";
import Validator from "validator";

function Career(props) {
  const Dispatch = useDispatch();

  return (
    <>
      <div className="mt-10 p-5 sm:mt-0">
        <div className="lg:grid lg:grid-cols-3 lg:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Career Profile
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={props.onSubmit}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Profile Name
                      </label>
                      <input
                        type="text"
                        name="profile_name"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.profile_name}
                        onChange={(e) => {
                          Validator.isAlpha(e.target.value, "en-US", {
                            ignore: " ",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                        required
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Qualification
                      </label>
                      <input
                        type="text"
                        name="qualification"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.qualification}
                        onChange={(e) => {
                          Validator.isAlphanumeric(e.target.value, "en-US", {
                            ignore: " ",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Experience
                      </label>
                      <input
                        type="text"
                        name="experience"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.experience}
                        onChange={(e) => {
                          Validator.isAlphanumeric(e.target.value, "en-US", {
                            ignore: " -_,';:&@",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Industry Type
                      </label>
                      <input
                        type="text"
                        name="industry_type"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.industry_type}
                        onChange={(e) => {
                          Validator.isAlpha(e.target.value, "en-US", {
                            ignore: " ",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Profile Description
                      </label>
                      <textarea
                        name="profile_description"
                        rows="4"
                        className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        value={props.profile_description}
                        onChange={(e) => {
                          Validator.isAlphanumeric(e.target.value, "en-US", {
                            ignore: " /[s/-]/g }],;:.@$%&()_-=/+*'!?",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?' ",
                                })
                              );
                        }}
                      ></textarea>
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Key Skill
                      </label>
                      <input
                        type="text"
                        name="key_skill"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.key_skill}
                        onChange={(e) => {
                          Validator.isAlpha(e.target.value, "en-US", {
                            ignore: " +-_'.,/&",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Location
                      </label>
                      <input
                        type="text"
                        name="location"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.location}
                        onChange={(e) => {
                          Validator.isAlpha(e.target.value, "en-US", {
                            ignore: " ",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="street_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Employment Type
                      </label>
                      <input
                        type="text"
                        name="employment_type"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.employment_type}
                        onChange={(e) => {
                          Validator.isAlpha(e.target.value, "en-US", {
                            ignore: " ",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Alpha Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal_code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Working Days
                      </label>
                      <input
                        type="text"
                        name="working_days"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.working_days}
                        onChange={(e) => {
                          Validator.isInt(e.target.value) ||
                          e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Number Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label
                        htmlFor="postal_code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        No of Job Opening
                      </label>
                      <input
                        type="text"
                        name="no_of_job"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                        value={props.no_of_job}
                        onChange={(e) => {
                          Validator.isInt(e.target.value) ||
                          e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Enter Number Only",
                                })
                              );
                        }}
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                        Status
                      </label>

                      <select
                        className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="status"
                        value={props.status}
                        onChange={(e) => {
                          e.target.selectedIndex !== 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message: "Please Select Status",
                                })
                              );
                        }}
                        required
                      >
                        <option value="">Choose Status</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                      </select>
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Responsibility
                      </label>
                      <textarea
                        name="responsibility"
                        rows="4"
                        className="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        value={props.responsibility}
                        onChange={(e) => {
                          Validator.isAlphanumeric(e.target.value, "en-US", {
                            ignore: " /[s/-]/g }],;:.@$%&()_-=/+*'!?",
                          }) || e.target.value.length === 0
                            ? props.onChange(e)
                            : Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                                })
                              );
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 flex justify-end space-x-2 text-right sm:px-6">
                  <button
                    type="button"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none"
                    onClick={props.onCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Career;
