import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthAction } from "../Authentication/AuthSlice";
import { useParams } from "react-router-dom";
import EmployeeForm from "./EmployeeForm";
import axios from "axios";
import { useHistory } from "react-router-dom";

function UpdateForm() {
  const Dispatch = useDispatch();

  const History = useHistory();

  const [emp_data, setemp_data] = useState({
    emp_name: "",
    emp_email: "",
    emp_mobile: "",
    emp_id: "",
    emp_designation: "",
    emp_avatar: [],
    monitor: "",
    keyboard: "",
    mouse: "",
    system_unit: "",
    processor: "",
    storage: "",
    motherboard: "",
    RAM: "",
    graphic_card: "",
    gadgets: "",
  });

  const [ImgPath, setImgPath] = useState("");

  const { id } = useParams();

  useEffect(() => {
    axios
      .put(`/emp/get/${id}`)
      .then((res) => {
        if (res.status === 200) {
          const newData = res.data.data;

          setemp_data({
            ...newData,
          });
        }
      })
      .catch((err) => {
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Invalid ID! Try after some time",
          })
        );
      });
  }, [Dispatch, id]);

  const ChangeHandler = (event, file) => {
    const Value = event.target.value;

    if (event.target.name === "emp_avatar") {
      setemp_data({
        ...emp_data,
        emp_avatar: file,
      });

      setImgPath(URL.createObjectURL(file));
    } else {
      setemp_data({
        ...emp_data,
        [event.target.name]: Value,
      });
    }
  };

  const EmployeeData = new FormData();

  const SubmitHandler = (event) => {
    event.preventDefault();

    Dispatch(AuthAction.Loader());

    for (let keys in emp_data) {
      EmployeeData.append(keys, emp_data[keys]);
    }

    axios
      .post(`/emp/${id}`, EmployeeData)
      .then((res) => {
        if (res.status === 200) {
          Dispatch(AuthAction.Loader());
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Employee Data Successfully Update",
            })
          );
        }
      })
      .then(() => {
        setTimeout(() => {
          History.push("/employee_list");
        }, 500);
      })
      .catch((err) => {
        Dispatch(AuthAction.Loader());
        History.push("/employee");
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );

        Object.keys(emp_data).map((key) => EmployeeData.delete(key));
      });
  };

  const ReAssign = () => {
    setemp_data({
      ...emp_data,
      emp_name: "",
      emp_email: "",
      emp_mobile: "",
      emp_id: "",
      emp_designation: "",
      emp_avatar: [],
    });
  };

  return (
    <EmployeeForm
      id={id}
      ImgPath={ImgPath}
      emp_name={emp_data.emp_name}
      emp_email={emp_data.emp_email}
      emp_mobile={emp_data.emp_mobile}
      emp_id={emp_data.emp_id}
      emp_designation={emp_data.emp_designation}
      emp_avatar={emp_data.emp_avatar}
      monitor={emp_data.monitor}
      keyboard={emp_data.keyboard}
      mouse={emp_data.mouse}
      system_unit={emp_data.system_unit}
      processor={emp_data.processor}
      storage={emp_data.storage}
      motherboard={emp_data.motherboard}
      graphic_card={emp_data.graphic_card}
      gadgets={emp_data.gadgets}
      RAM={emp_data.RAM}
      ChangeHandler={ChangeHandler}
      SubmitHandler={SubmitHandler}
      ReAssign={ReAssign}
    />
  );
}

export default UpdateForm;
