import "./App.css";
import { Switch, Route } from "react-router-dom";

import PrivateRoute from "./Components/Authentication/PrivateRoute";

// Login
import Login from "./Components/Login";
import NotFound from "./Components/404";

// Routes
import Routes from "./Routes";

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        {Routes.map((route, index) => {
          return (
            <PrivateRoute
              key={index + 1}
              Role={route.role}
              exact
              name={route.name}
              path={route.path}
              Component={route.component}
            />
          );
        })}

        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
