import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Row, Col, Card,  Button, Form,  Accordion,Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams,useHistory } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";
import axios from "axios";

async function uploadImageCallBack(file) {
    console.log(file)
    // return new Promise(
    //   (resolve, reject) => {
    // resolve({ data: { link: URL.createObjectURL(file) } });
    //   })
    //return;
    //  xhr.open('POST', 'http://admin.shunyaekai.tech/category/api/v1/blogimageupload');
             return new Promise(
               (resolve, reject) => {
                 const xhr = new XMLHttpRequest();
                 xhr.open('POST', 'https://admin.shunyaekai.tech/category/api/v1/blogimageupload');
             
                 const data = new FormData();
                 data.append('blogimage', file);
                 xhr.send(data);
                 xhr.addEventListener('load', () => {
                    
                  const response =JSON.parse(xhr.responseText);
                   resolve({ data: { link: response.url } });

                 });
                 xhr.addEventListener('error', () => {
                     console.log(xhr.responseText)
                   reject(xhr.responseText);
                 });
               }
             );
           }
     
  
const Editblog = () => {
  const [selectedFontFamily, setSelectedFontFamily] = useState('productLight');
  const [selectedFontSize, setSelectedFontSize] = useState('16px');

    const customStyleMap = {
        fontFamily: {
          fontFamily: 'ProductLight',
           value:'productLight'
        },
        fontSizes: {
          fontSize: selectedFontSize,
        },
      };
      
    const Dispatch = useDispatch();
    let { id } = useParams();
    const [detail, setDetails] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [editorState, setEditorState] = useState()
    const [picture, setPicture] = React.useState(null);
    const history = useHistory();

      const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
      };

    useEffect(()=>{
    
     console.log(id)
  
     axios
     .post("/category/api/v1/detailBlog",{id:id})
     .then((res) => {
       console.log(res)
        if (res.data.status === true) {
      
           setDetails(res.data.response);
           setFileName(res.data.response.blog_url);
          const contentState = convertFromRaw(JSON.parse(res.data.response.blog_block));
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
          setcategoryName(res.data.response.blog_categories)

        }else{
           Dispatch(
               AuthAction.showNotification({
                 type: "error",
                 message: res.data.message,
               })
             );  
        }
     })
     .catch((err) => {
       Dispatch(
         AuthAction.showNotification({
           type: "error",
           message: "Please Enter Valid Data",
         })
       );
     })
 },[])

  

   
    const [image, setImage] = useState(null)
    const [fileName, setFileName] = useState("No selected file")

   
    const[categorylist,setListcategory]=useState([])
    const addCategory=()=>{
        axios
        .post("/category/api/v1/addcategory", {categoryName:categoryName})
        .then((res) => {
          console.log(res)
           if (res.data.status === true) {
            Dispatch(
              AuthAction.showNotification({
                type: "success",
                message: "Successfully Added",
              })
            );
           setListcategory(oldArray => [res.data.response,...oldArray] );
            setcategoryName('')
           }else{
              Dispatch(
                  AuthAction.showNotification({
                    type: "error",
                    message: res.data.message,
                  })
                );  
           }
        })
        .catch((err) => {
          Dispatch(
            AuthAction.showNotification({
              type: "error",
              message: "Please Enter Valid Data",
            })
          );
        })
    }
    const List=()=>{
        axios
        .get("/category/api/v1/Listcategory")
        .then((res) => {
          console.log(res)
           if (res.data.status === true) {
            setListcategory(res.data.response)
           }else{
              Dispatch(
                  AuthAction.showNotification({
                    type: "error",
                    message: res.data.message,
                  })
                );  
           }
        })
        .catch((err) => {
          Dispatch(
            AuthAction.showNotification({
              type: "error",
              message: "Please Enter Valid Data",
            })
          );
        })

    }
   
    useEffect(()=>{
        List();
    },[])

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [show, setShow] = useState(false);
    const [getData, setgetData] = useState(null);
    const handleClose = () => {setShow(false);
      setgetData(null)
    }
    const handleShow = (item) =>{ 
      setShow(true);
      setgetData(item);
    }
    
    const DeleteCategory=()=>{
      axios
      .post("/category/api/v1/deleteCategory", {id:getData._id})
      .then((res) => {
        console.log(res)
         if (res.data.status === true) {
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Successfully Deleted",
            })
          );
          const newArray = categorylist.filter((item, i) => item._id !== getData._id);
         setListcategory(newArray);
         setShow(false);
         }else{
            Dispatch(
                AuthAction.showNotification({
                  type: "error",
                  message: res.data.message,
                })
              );  
         }
      })
      .catch((err) => {
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );
      })
  }
   


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };
      const[categoryName,setcategoryName]=useState('');

      const changeCategory=(e)=>{
        setcategoryName(e.target.value)
      }

     const changeHandler = (e) => {
        setDetails({ ...detail, [e.target.name]: e.target.value })
     }
     const updateData=()=>{
       detail.blog_categories=categoryName;
        const contentState = editorState.getCurrentContent();
            const rawContentState = convertToRaw(contentState);
            const text = rawContentState.blocks
            .map((block) => block.text)
            .join('\n');
            let htmlData=draftToHtml(convertToRaw(editorState.getCurrentContent()));


      if(detail.blog_title=="" ||detail.blog_title==undefined){

        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please enter title",
          })
        );
    }
   else if(detail.blog_categories=="" ||detail.blog_categories==undefined){

        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please select category",
          })
        );
    }
  
    else if(detail.metatitle=="" ||detail.metatitle==undefined){
    
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please enter Meta Title",
          })
        );
    }
    else if(detail.metadiscription=="" ||detail.metadiscription==undefined){
    
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please enter Meta description",
          })
        );
    }
    else if(detail.metatag=="" ||detail.metatag==undefined){
     
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please enter Meta keywords",
          })
        );
    }
    else if(htmlData.length==8){
    
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please enter description",
          })
        );
    }
    else{

         if(picture=="" ||picture==null){
            detail.blog_description=htmlData;
            detail.blog_block=JSON.stringify(rawContentState);
            detail.blog_plaintxt=text;
            
            axios
            .post("/category/api/v1/updateBlogdetails", detail)
            .then((res) => {
               if (res.data.status === true) {
                Dispatch(
                  AuthAction.showNotification({
                    type: "success",
                    message: "Blog Successfully Added",
                  })
                );
                setSelectedOption('');
               }else{
                  Dispatch(
                      AuthAction.showNotification({
                        type: "error",
                        message: res.data.message,
                      })
                    );  
               }
            })
            .then(() =>
            setTimeout(() => {
              history.push("/blog_list");
            }, 1500)
          )
          .catch((err) => {
              Dispatch(
                AuthAction.showNotification({
                  type: "error",
                  message: "Please Enter Valid Data",
                })
              );

            });
        

        }else{
            detail.blog_description=htmlData;
            const addFormData = new FormData();
            addFormData.append("_id", detail._id);
            addFormData.append("blog_title", detail.blog_title);
            addFormData.append("blog_description", detail.blog_description)
            addFormData.append("blog_image", picture)
            addFormData.append("blog_categories", detail.blog_categories)
            addFormData.append("metatitle", detail.metatitle)
            addFormData.append("metadiscription", detail.metadiscription)
            addFormData.append("metatag", detail.metatag)
            addFormData.append("blog_block", JSON.stringify(rawContentState))
            addFormData.append("blog_plaintxt", text)
            addFormData.append("status", true)
            addFormData.append("blog_url", detail.blog_url)
            addFormData.append("blogsmall_url", detail.blogsmall_url)
            addFormData.append("blogmedium_url", detail.blogmedium_url)
            
      axios
      .post("/category/api/v1/updateBlogdetails", addFormData)
      .then((res) => {
                if (res.data.status === true) {
                Dispatch(
                    AuthAction.showNotification({
                    type: "success",
                    message: "Blog Successfully Added",
                    })
                );
                setSelectedOption('');
            
                }else{
                        Dispatch(
                            AuthAction.showNotification({
                            type: "error",
                            message: res.data.message,
                            })
                        );  
                    }
                })
                .then(() =>
                setTimeout(() => {
                    history.push("/blog_list");
                }, 1500)
                )
            .catch((err) => {
                Dispatch(
                AuthAction.showNotification({
                    type: "error",
                    message: "Please Enter Valid Data",
                })
                );
                addFormData.delete("blog_title");
                addFormData.delete("blog_description");
                addFormData.delete("blog_image");
                addFormData.delete("blog_categories");
                addFormData.delete("metatitle");
                addFormData.delete("metadiscription");
                addFormData.delete("metatag");
            });

        }
      }

     }
     function validateImage(file) {
      var image = new Image();
    
      image.src = URL.createObjectURL(file);
      var imageLoaded = false;
        image.onload = function() {
        
          if (image.width != 1024 && image.height != 520) {
            imageLoaded = true;
          }
          // call a function to handle the result
          handleImageValidationResult(file,imageLoaded);
        };
  
    }
    function handleImageValidationResult(files,isValid) {
    
      if(isValid){
        alert("Please upload width 1024px and height 500px")
      }else{
        if(files.size<511741){
         
          files && setFileName(files.name);
          setPicture(files);
        }else{
          setFileName("No Selected File ");
          setPicture(null);
          alert("Please upload maximum 500kb image size")
        }
      }
       
    }
    return (
        <>
            <Row className="m-0">
                <Col>
                    <Card className='p-3'>
                        <Row className='w-100 add-container'>
                            <div className='add-left-container'>
                                <Row>
                                    <Col>
                                        <Form.Group className="form-group position-relative tooltip-end-top mb-3">
                                            <Form.Label className='px-1 fw-bolder'>Title</Form.Label>
                                            <Form.Control type="text" name="blog_title" onChange={changeHandler} value={detail && detail.blog_title} />
                                          
                                        </Form.Group>
                                    </Col>
                                </Row >

                                <Row>
                                    <Col style={{
                                        border: '1px solid',
                                        borderColor: 'whitesmoke',
                                        margin: '1rem 0px',
                                        padding: '1rem',
                                    }} >
                                        <Editor
                                               editorState={editorState}
                                               onEditorStateChange={onEditorStateChange}
                                               editorStyle={{
                                                border: '1px solid', height: '80vh',
                                                borderColor: 'whitesmoke',
                                                padding: '0px 10px'
                                            }}
                                            wrapperClassName="wrapper-class"
                                            editorClassName="editor-class"
                                            toolbarClassName="toolbar-class"
                                            customStyleMap={customStyleMap}
                                            toolbar={{
                                                fontFamily: {
                                                    options: ['Arial', 'Georgia', 'Impact','ProductLight', 'Tahoma', 'Times New Roman', 'Verdana'],
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                   // title: selectedFontFamily,
                                                    type: 'dropdown',
                                                    onChange: (fontFamily) => {
                                                      setSelectedFontFamily(fontFamily);
                                                    },
                                                    value: selectedFontFamily,
                                                  },
                                                  fontSize: {
                                                    options: [12, 14, 16, 18, 20],
                                                  },
                                               
                                                video: {
                                                    defaultSize: {
                                                    height: 'auto',
                                                    width: 'auto',
                                                    },
                                                    video:{
                                                    uploadEnabled: true,
                                                //  uploadCallback: handleVideoUpload,
                                                    previewImage: true,
                                                    alignmentEnabled: true,
                                                    }
                                                },
                                                image: {
                                                    uploadCallback: uploadImageCallBack,
                                                    previewImage: true,
                                                // previewSrcFn: previewImage,
                                                    inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                                    alt: { present: true, mandatory: true }
                                                },
                                            }}
                                            onBlur={() => {}}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="form-group position-relative tooltip-end-top mb-3">
                                            <Form.Label className='px-1 fw-bolder'>Meta Title</Form.Label>
                                            <Form.Control type="text" name="metatitle" onChange={changeHandler} value={detail && detail.metatitle} />
                                           
                                        </Form.Group>
                                    </Col>
                                </Row >

                                <Row>
                                    <Col>
                                        <Form.Group className="form-group position-relative tooltip-end-top mb-3">
                                            <Form.Label className='px-1 fw-bolder'>Meta Description</Form.Label>
                                            <Form.Control type="text" name="metadiscription" onChange={changeHandler} value={detail && detail.metadiscription} />
                                          
                                        </Form.Group>
                                    </Col>
                                </Row >

                                <Row>
                                    <Col>
                                        <Form.Group className="form-group position-relative tooltip-end-top mb-3">
                                            <Form.Label className='px-1 fw-bolder'>Meta Keyword</Form.Label>
                                            <Form.Control type="text" name="titleName" onChange={changeHandler} value={detail && detail.metatag} />
                                          
                                        </Form.Group>
                                    </Col>
                                </Row >
                            </div>


                            <div className='add-right-container pt-2'>
                                <Row>
                                    <form className='form-handler border-0 px-5'
                                        onClick={() => document.querySelector(".input-field").click()}>
                                        <input type="file" accept='image/*' className='input-field' hidden
                                            onChange={({ target: { files } }) => {
                                             
                                              validateImage(files[0])
                                            }}  />

                                        {image ?
                                            <img src={image} width={200} height={200} alt="fileName" style={{ objectFit: 'contain' }} />
                                            :
                                            <>
                                                <MdCloudUpload className='text-primary' size={60} />
                                                <p style={{fontSize:'10px'}}>Upload feature image (500KB 1024X520px)</p>
                                            </>
                                        }

                                        <section className='uploded-row'>
                                            <span>
                                                {fileName}
                                                <MdDelete
                                                    size={20}
                                                    className='float-end mx-2 text-primary'
                                                    onClick={() => {
                                                        setFileName(" No Selected File ")
                                                        setImage(null)
                                                    }}
                                                />
                                            </span>
                                        </section>
                                    </form>
                                </Row>

                                <Row>
                                    <Col>
                                        <Card className='border mb-2'>
                                            <Row className='text-primary'>
                                                <h4 className='px-4 pt-1 mb-2 fs-6'>Categories</h4>
                                            </Row>
                                            <Row>
                                                <Col className='mx-2 mb-3'>
                                                    <button className='border px-2 w-100 text-start' >
                                                        All Categories
                                                    </button>
                                                </Col>
                                            </Row>

                                        
                                            <div className="category-handler">
                                                {
                                                categorylist.length>0 && categorylist.map((item,index)=>{
                                                    return(
                                                      <div  key={index} onMouseEnter={() => setHoveredIndex(index)}
                                                      onMouseLeave={() => setHoveredIndex(null)}>
                                                      <p style={{marginTop: '2px',padding: '3px',borderBlockEnd: '1px solid gray'}}>
                                                    <label>
                                                    <input type="radio" name='category' checked={detail && item._id==categoryName} value={item._id}  onChange={changeCategory} /> {item.categoryName}
                                                    </label>
                                                    {index==hoveredIndex ?  
                                                      <MdDelete
                                                    size={20}
                                                    className='float-end mx-2 text-danger'  
                                                    onClick={()=>{
                                                      handleShow(item);
                                                    }}
                                                    /> 
                                                :null}
                                                    </p>
                                                    </div>
                                                    )
                                                })
                                                }
                                            
                                                                                          
                                               
                                            </div>

                                            <Col className='m-2'>
                                                <Accordion>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header className='text-primary fw-bolder' style={{ margin: '-15px', fontSize: '10px', padding: '1rem 5px ' }}>  <AddIcon style={{ fontSize: '15px', margin: '0px 5px' }} />Add Categories</Accordion.Header>
                                                        <Accordion.Body style={{ padding: '5px 10px' }}>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Control  placeholder="Add category here"  type="text" name='categoryName' onChange={changeCategory} />
                                                                    <Button variant="outline-secondary" size="sm" className='float-end px-5' onClick={addCategory} >Add</Button>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </Col>
                                        </Card>
                                    </Col>
                                </Row >
                            </div>
                        </Row>
                        <Col className='pb-2'>
                            <Button className='m-2 px-5 fs-6 mx-5 float-end border-0' type="button" onClick={updateData} style={{ backgroundColor: "#38BDF8" }}>Publish</Button>
                        </Col>
                    </Card>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Operation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button  onClick={handleClose}>
            Close
          </Button>
          <Button onClick={DeleteCategory}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
}
export default Editblog;


