import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";

import axios from "axios";

function OldEmployeeList() {
  const Dispatch = useDispatch();

  const [EmployeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    axios
      .get("/emp/old")
      .then((res) => {
        if (res.status === 200) {
          setEmployeeData(res.data.data);
        }
      })
      .catch((err) => {
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "No Data Found!",
          })
        );
      });
  }, [Dispatch]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Employee ID
            </th>
            <th scope="col" className="px-6 py-3">
              Employee Name
            </th>
            <th scope="col" className="px-6 py-3">
              Employee Email
            </th>
            <th scope="col" className="px-6 py-3">
              Employee Mobile
            </th>
            <th scope="col" className="px-6 py-3">
              Employee Designation
            </th>
          </tr>
        </thead>
        <tbody>
          {EmployeeData.map((data) => {
            return (
              <tr
                key={data._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {data.emp_id}
                </th>
                <td className="px-6 py-4 overflow-elip">{data.emp_name}</td>
                <td className="px-6 py-4">{data.emp_email}</td>
                <td className="px-6 py-4">{data.emp_mobile}</td>
                <td className="px-6 py-4">{data.emp_designation}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default OldEmployeeList;
