import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AuthAction } from "../Authentication/AuthSlice";
import axios from "axios";

import Form from "./Form";
import { useHistory } from "react-router-dom";

function AddCourse() {
  const Dispatch = useDispatch();

  const History = useHistory();

  const [ImageName, setImageName] = useState("");
  const [Course, setCourse] = useState({
    title: "",
    about: "",
    level: "",
    duration: "",
    price: "",
    image: "",
    status: "",
  });

  const ChangeHandler = (event, Name, File) => {
    const Value = event.target.value;

    if (event.target.name === "image") {
      setCourse({
        ...Course,
        image: File,
      });

      setImageName(Name);
    } else {
      setCourse({
        ...Course,
        [event.target.name]: Value,
      });
    }
  };

  const CourseData = new FormData();

  const SubmitHandler = (event) => {
    event.preventDefault();
    Dispatch(AuthAction.Loader());

    CourseData.append("course_title", Course.title);
    CourseData.append("course_description", Course.about);
    CourseData.append("course_level", Course.level);
    CourseData.append("course_duration", Course.duration);
    CourseData.append("course_image", Course.image);
    CourseData.append("course_price", Course.price);
    CourseData.append("course_status", Course.status);

    axios
      .post("/course", CourseData)
      .then((res) => {
        if (res.status === 201) {
          Dispatch(AuthAction.Loader());
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Course Successfully Added",
            })
          );
        }
      })
      .then(() =>
        setTimeout(() => {
          History.push("/course_list");
        }, 1500)
      )
      .catch((err) => {
        Dispatch(AuthAction.Loader());
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );

        CourseData.delete("course_title");
        CourseData.delete("course_description");
        CourseData.delete("course_level");
        CourseData.delete("course_duration");
        CourseData.delete("course_image");
        CourseData.delete("course_price");
        CourseData.delete("course_status");
      });
  };

  const CancelHandler = () => {
    setCourse({
      title: "",
      about: "",
      level: "",
      duration: "",
      image: "",
      status: "",
    });
    setImageName("");
  };

  return (
    <Form
      title={Course.title}
      about={Course.about}
      price={Course.price}
      level={Course.level}
      duration={Course.duration}
      status={Course.status}
      image={Course.image}
      ImageName={ImageName}
      SubmitHandler={SubmitHandler}
      ChangeHandler={ChangeHandler}
      onCancel={CancelHandler}
    />
  );
}

export default AddCourse;
