import React, { useState } from "react";
import { Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Rating from 'react-rating';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BlogView = () => {


    return (
        <>

            <Row className="m-0">
                <Col lg="12" xxl="12">
                    <Card className="mb-3">
                        <div className='blog-handler d-flex'>
                            <Col className="img-blog" lg="4" xxl="3 col-md-4">
                                <div>
                                    <Card className="m-3">
                                        {/* <img src="/img/icon.jpg" className="rounded-3" alt="card image" /> */}
                                        <img className="w-100 rounded-3" src="https://images.unsplash.com/photo-1679458118229-6ac5b35757d4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw1fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=500&q=60" alt="card image"
                                            style={{ objectFit: 'fill', height: '450px' }} />
                                    </Card>
                                </div>
                            </Col>

                            <Card.Body>
                                <div className="d-flex flex-row align-content-center align-items-center">
                                    <div className="mb-0 text-alternate">

                                        <h1 className="fw-bolder fs-4 pb-3" style={{ color: '#38BDF8' }}>
                                            Claritas est etiam processus dynamicus.
                                        </h1>
                                    </div>
                                </div>

                                <div className="d-flex flex-row align-content-center align-items-center mt-4 mb-4">
                                    <div className="mt3-0 mb-3 fs-6 text-alternate">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
                                        remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                        and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </div>
                                </div>
                            </Card.Body>
                        </div>
                    </Card>
                </Col>
            </Row>

        </>
    )
}

export default BlogView