import React from "react";
import { useDispatch } from "react-redux";
import { AuthAction } from "../Authentication/AuthSlice";
import Validator from "validator";

function Form(props) {
  const Dispatch = useDispatch();

  return (
    <>
      <div className="mt-5md:mt-0 md:col-span-2">
        <form onSubmit={props.SubmitHandler}>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Course Title
                </label>
                <div className="mt-1">
                  <input
                    id="title"
                    name="title"
                    className="p-1 pl-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Course Title"
                    value={props.title}
                    onChange={(e) => {
                      if (
                        Validator.isAlpha(e.target.value, "en-US", {
                          ignore: " ",
                        }) ||
                        e.target.value.length === 0
                      ) {
                        props.ChangeHandler(e);
                      } else {
                        Dispatch(
                          AuthAction.showNotification({
                            type: "error",
                            message: "Please Enter Alpha Only",
                          })
                        );
                      }
                    }}
                    required
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  Course Description
                </label>
                <div className="mt-1">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="shadow-sm pl-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Course Description"
                    value={props.about}
                    onChange={(e) => {
                      if (
                        Validator.isAlphanumeric(e.target.value, "en-US", {
                          ignore: ` /[s/-]/g }],;:.@$%&()_-=/+*'"!?`,
                          allow_enter: true,
                        }) ||
                        e.target.value.length === 0
                      ) {
                        props.ChangeHandler(e);
                      } else {
                        Dispatch(
                          AuthAction.showNotification({
                            type: "error",
                            message:
                              "Please use AlphaNumeric and ', . ; : @ $ % & ( ) - ! ?'",
                          })
                        );
                      }
                    }}
                    required
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Course Duration
                  </label>

                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="duration"
                    value={props.duration}
                    onChange={(e) => {
                      e.target.selectedIndex !== 0
                        ? props.ChangeHandler(e)
                        : Dispatch(
                          AuthAction.showNotification({
                            type: "error",
                            message: "Please Select Category",
                          })
                        );
                    }}
                    required
                  >
                    <option value="">Select Duration</option>
                    <option value="45">45 Min</option>
                    <option value="60">60 Min</option>
                    <option value="90">90 Min</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Course Level
                  </label>

                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="level"
                    value={props.level}
                    onChange={(e) => {
                      e.target.selectedIndex !== 0
                        ? props.ChangeHandler(e)
                        : Dispatch(
                          AuthAction.showNotification({
                            type: "error",
                            message: "Please Select Category",
                          })
                        );
                    }}
                    required
                  >
                    <option value="">Select Level</option>

                    <option value="Fundamental">Fundamental</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advance">Advance</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-3 sm:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Status
                  </label>

                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="status"
                    value={props.status}
                    onChange={(e) => {
                      e.target.selectedIndex !== 0
                        ? props.ChangeHandler(e)
                        : Dispatch(
                          AuthAction.showNotification({
                            type: "error",
                            message: "Please Select Status",
                          })
                        );
                    }}
                    required
                  >
                    <option value="">Select Status</option>

                    <option value="0">0</option>
                    <option value="1">1</option>
                  </select>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Course Price
                </label>
                <div className="mt-1">
                  <input
                    name="price"
                    className="p-1 pl-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Course Price"
                    value={props.price}
                    onChange={(e) => {
                      Validator.isInt(e.target.value) ||
                        e.target.value.length === 0
                        ? props.ChangeHandler(e)
                        : Dispatch(
                          AuthAction.showNotification({
                            type: "error",
                            message: "Please Enter Number Only",
                          })
                        );
                    }}
                    required
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Cover photo
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    {!props.ImageName ? (
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <p className="font-light dark:text-white">
                        {props.ImageName}
                      </p>
                    )}
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="image"
                          type="file"
                          className="sr-only"
                          onChange={(e) => {
                            if (
                              (e.target.files[0].type.split("/")[1] ===
                                "jpeg" ||
                                e.target.files[0].type.split("/")[1] ===
                                "jpg" ||
                                e.target.files[0].type.split("/")[1] ===
                                "png") &&
                              e.target.files[0].size < 1024000
                            ) {
                              props.ChangeHandler(
                                e,
                                e.target.files[0].name,
                                e.target.files[0]
                              );
                            } else {
                              Dispatch(
                                AuthAction.showNotification({
                                  type: "error",
                                  message:
                                    "Please Upload on JPEG, JPG & PNG up to 1MB",
                                })
                              );
                            }
                          }}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      PNG, JPG, GIF up to 1MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 flex justify-end space-x-2 text-right sm:px-6">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none"
                onClick={props.onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Form;
