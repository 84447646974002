import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { AuthAction } from "../Authentication/AuthSlice";
import Form from "./Form";

function UpdateCourse() {
  const Dispatch = useDispatch();

  const History = useHistory();

  const { id } = useParams();

  const [ImageName, setImageName] = useState("");
  const [UpdateCourse, setUpdateCourse] = useState({
    title: "",
    about: "",
    level: "",
    duration: "",
    price: "",
    image: "",
    status: "",
  });

  const UpdateData = new FormData();

  useEffect(() => {
    axios.get(`/course/one/${id}`).then((res) => {
      const newData = res.data.data;

      setUpdateCourse({
        ...UpdateCourse,
        title: newData.course_title,
        about: newData.course_description,
        level: newData.course_level,
        duration: newData.course_duration,
        price: newData.course_price,
        status: newData.course_status,
      });

      setImageName(`${newData.course_img}`.split("/")[1]);
    });
  }, [id]);

  const ChangeHandler = (event, Name, File) => {
    const Value = event.target.value;

    if (event.target.name === "image") {
      setUpdateCourse({
        ...UpdateCourse,
        image: File,
      });

      setImageName(Name);
    } else {
      setUpdateCourse({
        ...UpdateCourse,
        [event.target.name]: Value,
      });
    }
  };

  const SubmitHandler = (event) => {
    event.preventDefault();
    Dispatch(AuthAction.Loader());

    UpdateData.append("course_title", UpdateCourse.title);
    UpdateData.append("course_description", UpdateCourse.about);
    UpdateData.append("course_level", UpdateCourse.level);
    UpdateData.append("course_duration", UpdateCourse.duration);
    UpdateData.append("course_image", UpdateCourse.image);
    UpdateData.append("course_price", UpdateCourse.price);
    UpdateData.append("course_status", UpdateCourse.status);

    axios
      .post(`/course/one/${id}`, UpdateData)
      .then((res) => {
        if (res.status === 200) {
          Dispatch(AuthAction.Loader());
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Course Successfully Update",
            })
          );
        }
      })
      .then(() =>
        setTimeout(() => {
          History.push("/course_list");
        }, 1500)
      )
      .catch((err) => {
        Dispatch(AuthAction.Loader());
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );

        UpdateData.delete("course_title");
        UpdateData.delete("course_description");
        UpdateData.delete("course_level");
        UpdateData.delete("course_duration");
        UpdateData.delete("course_image");
        UpdateData.delete("course_price");
        UpdateData.delete("course_status");
      });
  };

  const CancelHandler = () => {
    History.push("/course_list");
  };

  return (
    <Form
      title={UpdateCourse.title}
      about={UpdateCourse.about}
      price={UpdateCourse.price}
      level={UpdateCourse.level}
      duration={UpdateCourse.duration}
      status={UpdateCourse.status}
      image={UpdateCourse.image}
      ImageName={ImageName}
      SubmitHandler={SubmitHandler}
      ChangeHandler={ChangeHandler}
      onCancel={CancelHandler}
    />
  );
}

export default UpdateCourse;
