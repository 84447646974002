import React, { useState, useEffect } from "react";

import { Row, Col, Card, Button, Badge } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Rating from 'react-rating';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { NavLink, useLocation, useParams } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";
import axios from "axios";

const BlogDetails = () => {
    const Dispatch = useDispatch();
    let { id } = useParams();
    const [detail, setDetails] = useState(null);

   useEffect(()=>{
  
    console.log(id)
  //  return;
    axios
    .post("/category/api/v1/detailBlog",{id:id})
    .then((res) => {
      console.log(res)
       if (res.data.status === true) {
        console.log(res.data.response);
        setDetails(res.data.response)
       }else{
          Dispatch(
              AuthAction.showNotification({
                type: "error",
                message: res.data.message,
              })
            );  
       }
    })
    .catch((err) => {
      Dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Please Enter Valid Data",
        })
      );
    })
},[])


    const accepted = () => toast('Accept Successfully!');
    const rejected = () => toast('Reject Successfully!');
    const canceled = () => toast('Cancel Successfully!');
    const deleted = () => toast('Delete Successfully!');

    // Delet modal 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

   
   

    return (
        <>

            <Row className="m-0">
                <Col lg="12" xxl="12">
                    <Card className="mb-3">
                        <div className='blog-handler d-flex'>
                          

                            <Card.Body>
                            <Col className="img-blog">
                             
                                 
                                        {/* <img src="/img/icon.jpg" className="rounded-3" alt="card image" /> */}
                                        <img className="w-100 rounded-3" src={ detail && `https://admin.shunyaekai.tech/uploads/${detail.blog_url}`}  alt="card image"
                                            style={{ objectFit: 'fill', height:'550px'}} />
                                   
                              
                            </Col>
                                <div className="d-flex flex-row align-content-center align-items-center">
                                    <div className="mb-0 text-alternate">

                                        <h1 className="fw-bolder fs-4 pb-3" style={{ color: '#38BDF8' }}>
                                        { detail && detail.blog_title}
                                        </h1>
                                    </div>
                                </div>

                                {/* <div className="d-flex flex-row align-content-center align-items-center">
                                    <div className="mb-0 text-alternate" style={{ color: '#38BDF8' }} >
                                        <span className='fs-5'>
                                            <span>26/02/2023</span> <span>|</span> <span>Plazathemes</span>
                                        </span>
                                    </div>
                                </div> */}

                                <div className="d-flex flex-row align-content-center align-items-center mt-4 mb-4">
                                    <div className="mt3-0 mb-3 fs-6 text-alternate" id="para">
                                    {
                                    detail &&  <p dangerouslySetInnerHTML={{ __html: detail.blog_description }} ></p>
                                   }
                                    </div>
                                </div>


                                {/* <div className="blog-btn">
                                    <button type="button" className="btn" style={{ backgroundColor: '#1e6c1ed1' }} onClick={accepted}>Accept</button>
                                    <button type="button" className="btn" style={{ backgroundColor: '#d71a1ad4' }} onClick={rejected}>Reject</button>

                                    <Link to="/edit_blog">
                                        <SaveAsOutlinedIcon className="text-primary pointer cursor-pointer mx-2" />
                                    </Link>
                                    <DeleteOutlineOutlinedIcon className="text-primary cursor-pointer ml-5" onClick={handleShow} style={{ marginLeft: '10px', fontSize: '1.7rem' }} />
                                </div> */}

                            </Card.Body>
                        </div>
                    </Card>
                </Col>



                <div>
                    <Modal show={show} onHide={handleClose}>
                        {/* <Modal.Header closeButton> */}
                        <Modal.Title className="px-3 pt-2">
                            <DeleteIcon className="text-primary mx-2" />
                            <span style={{ fontFamily: 'monospace' }}>
                                Delete
                            </span>

                        </Modal.Title>
                        <div>
                            <hr />
                            <p className="px-5 fs-6  pt-3 pb-3">
                                Are You Sure to delete this item !
                            </p>
                            <hr />
                        </div>
                        <div className="blog-btn d-flex align-items-center justify-content-end m-2" >
                            <Button className="btn mx-2" variant="secondary"
                                onClick={() => {
                                    handleClose();
                                    canceled();
                                }}
                            >Cancel
                            </Button>

                            <Button className="btn" variant="primary" onClick={() => {
                                handleClose();
                                deleted();
                            }}
                            >Delete
                            </Button>
                        </div>
                    </Modal>
                </div >
            </Row>

        </>
    )
}

export default BlogDetails