import React, { useEffect, useState } from "react";
import Validator from "validator";
import { useDispatch, useSelector } from "react-redux";

import { AuthAction, LoginHandler } from "./Authentication/AuthSlice";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faLock } from "@fortawesome/free-solid-svg-icons";

function Login() {
  const Dispatch = useDispatch();
  const { isAuth, isLoading } = useSelector((state) => state.Auth);
  const isLoggedin = localStorage.getItem("session");

  const History = useHistory();

  const [User, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isAuth || isLoggedin) {
      // History.push("/blog");
      History.push("/addblog");
    } else {
      History.push("/");
    }
  }, [isAuth, isLoggedin, History]);

  const ChangeHandler = (event) => {
    const Value = event.target.value;

    setUser({
      ...User,
      [event.target.name]: Value,
    });
  };

  const SubmitHandler = (event) => {
    event.preventDefault();

    if (!Validator.isEmail(User.email)) {
      return Dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Please Enter Valid Email",
        })
      );
    }

    Dispatch(LoginHandler(User));
  };

  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
        <div
          className="
          flex flex-col
          bg-white
          shadow-md
          px-4
          sm:px-6
          md:px-8
          lg:px-10
          py-8
          rounded-3xl
          w-50
          max-w-md
        "
        >
          <div className="font-medium self-center text-xl sm:text-3xl text-gray-800">
            Welcome Back
          </div>
          <div className="mt-4 self-center text-xl sm:text-sm text-gray-800">
            Enter your credentials to access your account
          </div>

          <div className="mt-10">
            <form
              onSubmit={SubmitHandler}
              className="flex flex-col items-center"
            >
              <div className="flex flex-col mb-5 w-full">
                <label
                  htmlFor="email"
                  className="mb-1 text-xs tracking-wide text-gray-600"
                >
                  E-Mail Address:
                </label>
                <div className="relative">
                  <div
                    className="
                    inline-flex
                    items-center
                    justify-center
                    absolute
                    left-0
                    top-0
                    h-full
                    w-10
                    text-gray-400
                  "
                  >
                    <FontAwesomeIcon icon={faAt} className="text-blue-500" />
                  </div>

                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="
                    text-sm
                    placeholder-gray-500
                    pl-10
                    pr-4
                    rounded-2xl
                    border border-gray-400
                    w-full
                    py-2
                    focus:outline-none focus:border-blue-400
                  "
                    placeholder="Enter your email"
                    onChange={ChangeHandler}
                    value={User.email}
                  />
                </div>
              </div>
              <div className="flex flex-col mb-6 w-full">
                <label
                  htmlFor="password"
                  className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                >
                  Password:
                </label>
                <div className="relative">
                  <div
                    className="
                    inline-flex
                    items-center
                    justify-center
                    absolute
                    left-0
                    top-0
                    h-full
                    w-10
                    text-gray-400
                  "
                  >
                    <FontAwesomeIcon icon={faLock} className="text-blue-500" />
                  </div>

                  <input
                    id="password"
                    type="password"
                    name="password"
                    className="
                    text-sm
                    placeholder-gray-500
                    pl-10
                    pr-4
                    rounded-2xl
                    border border-gray-400
                    w-full
                    py-2
                    focus:outline-none focus:border-blue-400
                  "
                    placeholder="Enter your password"
                    onChange={ChangeHandler}
                    value={User.password}
                  />
                </div>
              </div>

              <div className="flex w-full">
                <button
                  type="submit"
                  className="
                  flex
                  mt-2
                  items-center
                  justify-center
                  focus:outline-none
                  text-white text-sm
                  sm:text-base
                  bg-blue-500
                  hover:bg-blue-600
                  rounded-2xl
                  py-2
                  w-full
                  transition
                  duration-150
                  ease-in
                "
                >
                  <span className="mr-2 uppercase">Sign In</span>
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </button>
              </div>
              {isLoading && <div className="loader1 mt-4"></div>}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
