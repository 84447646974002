import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import Store from "./Store/Store";

axios.defaults.baseURL = "https://admin.shunyaekai.tech/";
//axios.defaults.baseURL = "http://localhost:4003/";

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ToastContainer autoClose={2000} />
    <Provider store={Store}>
      <App />
    </Provider>
  </BrowserRouter>
);
