import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";
import CareerForm from "./Career-Form";

function AddCareer() {
  const Dispatch = useDispatch();

  const History = useHistory();

  const [Career, setCareer] = useState({
    profile_name: "",
    profile_description: "",
    qualification: "",
    experience: "",
    industry_type: "",
    employment_type: "",
    key_skill: "",
    location: "",
    working_days: "",
    responsibility: "",
    no_of_job: "",
    status: "",
  });

  const ChangeHandler = (e) => {
    const Value = e.target.value;

    setCareer({
      ...Career,
      [e.target.name]: Value,
    });
  };

  const SubmitHandler = (e) => {
    e.preventDefault();

    Dispatch(AuthAction.Loader());

    axios
      .post("/career/add", Career)
      .then((res) => {
        if (res.status === 201) {
          Dispatch(AuthAction.Loader());
          Dispatch(
            AuthAction.showNotification({
              type: "success",
              message: "Career Successfully Post",
            })
          );
        }
      })
      .then(() =>
        setTimeout(() => {
          History.push("/career_list");
        }, 1500)
      )
      .catch((err) => {
        Dispatch(AuthAction.Loader());
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Please Enter Valid Data",
          })
        );
      });
  };

  return (
    <CareerForm
      profile_name={Career.profile_name}
      profile_description={Career.profile_description}
      qualification={Career.qualification}
      experience={Career.experience}
      industry_type={Career.industry_type}
      employment_type={Career.employment_type}
      key_skill={Career.key_skill}
      location={Career.location}
      working_days={Career.working_days}
      responsibility={Career.responsibility}
      no_of_job={Career.no_of_job}
      status={Career.status}
      onChange={ChangeHandler}
      onSubmit={SubmitHandler}
      onCancel={() =>
        setCareer({
          profile_name: "",
          profile_description: "",
          qualification: "",
          experience: "",
          industry_type: "",
          employment_type: "",
          key_skill: "",
          location: "",
          working_days: "",
          responsibility: "",
          no_of_job: "",
          status: "",
        })
      }
    />
  );
}

export default AddCareer;
