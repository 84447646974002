import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthAction } from "../Components/Authentication/AuthSlice";

const initialState = {
  PostData: [],
  SinglePost: {},
  UpdateModal: false,
};

const Blog_Reducer = createSlice({
  name: "Blog_Slice",
  initialState,
  reducers: {
    addData(state, action) {
      state.PostData = action.payload;
    },

    AddSingleData(state, action) {
      state.SinglePost = action.payload;
    },

    toggleModal(state, action) {
      state.UpdateModal = action.payload;
    },
  },
});

export const BlogAction = Blog_Reducer.actions;

export const PostBlog = (url, formData) => {
  return async (dispatch) => {
    const PostData = async () => {
      // for Loading
      dispatch(AuthAction.Loader());

      const Res = await axios.post(`/blog/${url}`, formData);

      if (Res.status === 201) {
        // for Loading
        dispatch(AuthAction.Loader());

        return dispatch(
          AuthAction.showNotification({
            type: "success",
            message: "Successfully Data Submitted",
          })
        );
      }
    };

    try {
      await PostData();
    } catch (err) {
      dispatch(AuthAction.Loader());

      dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Please Enter Valid Data",
        })
      );
    }
  };
};

export const getBlogs = (page) => {
  return async (dispatch) => {
    const AllBlogs = async () => {
      // for Loading
      dispatch(AuthAction.Loader());

      const Res = await axios.get(`/blog/gets/${page}`);

      if (Res.status === 200) {
        // for Loading
        dispatch(AuthAction.Loader());

        dispatch(BlogAction.addData(Res.data.data));

        if (Res.data.data.length === 0) {
          return dispatch(
            AuthAction.showNotification({
              type: "info",
              message: "No Data Found!",
            })
          );
        }
      }
    };

    try {
      await AllBlogs();
    } catch (err) {
      dispatch(AuthAction.Loader());

      dispatch(
        AuthAction.showNotification({
          type: "error",
          message: "Internal Server Error",
        })
      );
    }
  };
};

export default Blog_Reducer;
