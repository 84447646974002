import React, { useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";

import { useReactToPrint } from "react-to-print";
import QrCard from "../QRCode/QRCard";
import QRCode from "react-qr-code";

import axios from "axios";

function EmployeeList() {
  const Dispatch = useDispatch();

  const QRRef = useRef();
  const MultieQr = useRef();

  const QRPrintHandler = useReactToPrint({
    content: () => QRRef.current,
  });

  const MultipleQR = useReactToPrint({
    content: () => MultieQr.current,
  });

  const [EmployeeData, setEmployeeData] = useState([]);
  const [ShowModal, setShowModal] = useState({});

  useEffect(() => {
    axios
      .get("/emp")
      .then((res) => {
        if (res.status === 200) {
          setEmployeeData(res.data.data);
        }
      })
      .catch((err) => {
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "No Data Found!",
          })
        );
      });
  }, [Dispatch]);

  return (
    <>
      <div className="flex w-full justify-end">
        <button
          type="button"
          className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          onClick={MultipleQR}
        >
          Print All QR
        </button>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div style={{ display: "none" }}>
          <div ref={MultieQr} className="grid grid-cols-6 gap-3">
            {EmployeeData.map((value) => (
              <div key={value._id} className="flex items-center flex-col">
                <QRCode
                  value={value._id}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  size={64}
                />
                <p className="mb-3 font-normal text-gray-700">
                  {value.emp_name}
                </p>
              </div>
            ))}
          </div>
        </div>

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Employee ID
              </th>
              <th scope="col" className="px-6 py-3">
                Employee Name
              </th>
              <th scope="col" className="px-6 py-3">
                Employee Email
              </th>
              <th scope="col" className="px-6 py-3">
                Employee Mobile
              </th>
              <th scope="col" className="px-6 py-3">
                Employee Designation
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {EmployeeData.map((data) => {
              return (
                <tr
                  key={data._id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {data.emp_id}
                  </th>
                  <td className="px-6 py-4 overflow-elip">{data.emp_name}</td>
                  <td className="px-6 py-4">{data.emp_email}</td>
                  <td className="px-6 py-4">{data.emp_mobile}</td>
                  <td className="px-6 py-4">{data.emp_designation}</td>
                  <td className="px-6 py-4 flex flex-wrap gap-2 ">
                    <Link
                      to={`/employee/update/${data._id}`}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Edit
                    </Link>
                    <button
                      className="text-red-500"
                      style={{ margin: 0 }}
                      onClick={() =>
                        setShowModal({
                          [data.emp_name]: true,
                        })
                      }
                    >
                      QR Code
                    </button>
                  </td>

                  {ShowModal[data.emp_name] && (
                    <td className="qr-modal">
                      <QrCard
                        key={data._id}
                        id={data._id}
                        store={QRRef}
                        title={data.emp_name}
                        onClick={QRPrintHandler}
                        Close={() =>
                          setShowModal({
                            [data.emp_name]: false,
                          })
                        }
                      />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EmployeeList;
