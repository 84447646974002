import {
  faBlog,
  faBriefcaseMedical,
  faBriefcase,
  faFileCirclePlus,
  faList12,
  faUserTie,
  faUsers,
  faUserClock,
  faAddressBook,
  faFileLines,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import Blog from "./Components/Blog";
import AddBlog from "./Components/Blog/AddBlog";
import BlogDetails from "./Components/Blog/BlogDetails";
import Editblog from "./Components/Blog/Editblog";
import AddCourse from "./Components/Course/AddCourse";
import CourseList from "./Components/Course/CourseList";
import UpdateCourse from "./Components/Course/UpdateCourse";
import BlogList from "./Components/Blog/BlogList";
import BlogView from "./Components/Blog/BlogView";
import Career from "./Components/Career/AddCareer";
import CareerList from "./Components/Career/CareerList";
import UpdateCareer from "./Components/Career/UpdateCareer";
import Employee from "./Components/Employee/Employee";
import EmployeeList from "./Components/Employee/EmployeeList";
import UpdateForm from "./Components/Employee/UpdateForm";
import OldEmployeeList from "./Components/Employee/OldEmployeeList";


const Routes = [
  {
    name: "Blog",
    icon: faAddressBook,
    role: ["Admin", "ShunyaEkai", "RIOT"],
    path: "#",
    submenu: true,
    toggle: false,
    submenuItems: [
      { name: "Add Blog", component: AddBlog, path: "/addblog", icon: faUserPlus, },
     
      { name: "Blog List", component: BlogList, path: "/blog_list", icon: faFileLines, },
    ],
    component: Blog,
  },


  {
    name: "Add Blog",
    icon: faUserPlus,
    role: ["Admin", "ShunyaEkai", "RIOT"],
    path: "/addblog",
    submenu: false,
    component: AddBlog,
  },
  // {
  //   name: "Blogs",
  //   icon: faBlog,
  //   role: ["Admin", "ShunyaEkai", "RIOT"],
  //   path: "/blog",
  //   submenu: false,
  //   component: Blog,
  // },


  {
    name: "Blog List",
    icon: faFileLines,
    role: ["Admin", "ShunyaEkai", "RIOT"],
    path: "/blog_list",
    submenu: false,
    component: BlogList,
  },

  {
    name: "Edit Blog",
    icon: faBlog,
    role: ["Admin", "ShunyaEkai", "RIOT"],
    path: "/edit_blog/:id",
    submenu: false,
    component: Editblog,

  },


  {
    name: "Blog Details",
    icon: faBlog,
    role: ["Admin", "ShunyaEkai",],
    path: "/blog_detail/:id",
    submenu: false,
    component: BlogDetails,

  },

  {
    name: "Blog View",
    icon: faBlog,
    role: ["Admin", "ShunyaEkai",],
    path: "/blog_view",
    submenu: false,
    component: BlogView,

  },

  {
    name: "Add Career",
    icon: faBriefcaseMedical,
    role: ["Admin", "ShunyaEkai"],
    path: "/add_career",
    submenu: false,
    component: Career,

  },
  {
    name: "Career List",
    icon: faBriefcase,
    role: ["Admin", "ShunyaEkai"],
    path: "/career_list",
    submenu: false,
    component: CareerList,

  },
  {
    name: "Update Career",
    icon: faBriefcase,
    role: ["Admin", "ShunyaEkai"],
    path: "/career/update/:id",
    submenu: false,
    component: UpdateCareer,

  },
  {
    name: "Add Course",
    icon: faFileCirclePlus,
    role: ["Admin", "RIOT"],
    path: "/add_course",
    submenu: false,
    component: AddCourse,

  },
  {
    name: "Course List",
    icon: faList12,
    role: ["Admin", "RIOT"],
    path: "/course_list",
    submenu: false,
    component: CourseList,

  },
  {
    name: "Update Course",
    icon: faList12,
    role: ["Admin", "RIOT"],
    path: "/course/update/:id",
    submenu: false,
    component: UpdateCourse,

  },
  {
    name: "New Employee",
    icon: faUserTie,
    role: ["Admin", "ShunyaEkai"],
    path: "/add_employee",
    submenu: false,
    component: Employee,

  },
  {
    name: "Employee Data",
    icon: faUsers,
    role: ["Admin", "ShunyaEkai"],
    path: "/employee_list",
    submenu: false,
    component: EmployeeList,

  },
  {
    name: "Update Employee Data",
    icon: faUsers,
    role: ["Admin", "ShunyaEkai"],
    path: "/employee/update/:id",
    submenu: false,
    component: UpdateForm,

  },
  {
    name: "Old Employee Data",
    icon: faUserClock,
    role: ["Admin", "ShunyaEkai"],
    path: "/old_employee_list",
    submenu: false,
    component: OldEmployeeList,

  },
];

export default Routes;
