import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AuthAction } from "../Authentication/AuthSlice";

function CourseList() {
  const [CourseList, setCourseList] = useState([]);

  const Dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/course")
      .then((res) => {
        if (res.status === 200) {
          setCourseList(res.data.data);
        }
      })
      .catch((err) => {
        Dispatch(
          AuthAction.showNotification({
            type: "error",
            message: "Internal Server Error",
          })
        );
      });
  }, [Dispatch]);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Title
            </th>
            <th scope="col" className="px-6 py-3">
              Description
            </th>
            <th scope="col" className="px-6 py-3">
              Level
            </th>
            <th scope="col" className="px-6 py-3">
              Price
            </th>
            <th scope="col" className="px-6 py-3">
              Duration
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {CourseList.map((data) => {
            return (
              <tr
                key={data._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  {data.course_title}
                </th>
                <td className="px-6 py-4 overflow-elip">
                  {data.course_description}
                </td>
                <td className="px-6 py-4">{data.course_level}</td>
                <td className="px-6 py-4">${data.course_price}</td>
                <td className="px-6 py-4">{data.course_duration} days</td>
                <td className="px-6 py-4">
                  {data.course_status === "1" ? "Active" : "Deactive"}{" "}
                </td>
                <td className="px-6 py-4 ">
                  <Link
                    to={`/course/update/${data._id}`}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CourseList;
